import { ScrollView, View } from "react-native";
import { Avatar, Button, Switch, Text, useTheme } from "react-native-paper";
import { ThemeOverride } from "../../App";
import { ContentSurface } from "../components/ContentSurface";
import { LanguageList } from "../components/Preferences/LanguageList";
import { useErrorBox } from "../context/errorBox";
import { usePreferences } from "../context/preferences";
import { useEffect } from "react";
import { useUser } from "../context/user";

export const PreferencesScreen = ({ navigation }: any) => {
  const { state: prefsState, dispatch: prefsDispatch } = usePreferences();

  const theme: ThemeOverride = useTheme();

  const { addError } = useErrorBox();

  const { state: user, onLogin, onLogout, onSavePreferences } = useUser();

  const searchLangs = prefsState.searchLangs;
  const glossLangs = prefsState.glossLangs;

  // save preferences to server on unmount
  useEffect(() => {
    return () => {
      onSavePreferences();
    };
  }, []);

  return (
    <ScrollView>
      <ContentSurface>
        <View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 5 }}>
              <Text variant="headlineMedium">User Account</Text>
              <Text variant="bodySmall">
                {user.loggedIn
                  ? `You are logged in as ${user.userInfo?.name}`
                  : `You are not logged in. Press the button below to log in or create an account.`}
              </Text>
            </View>
            <View style={{ flex: 2, alignItems: "center" }}>
              {user.loggedIn && (
                <Avatar.Image
                  size={42}
                  source={{ uri: user.userInfo?.picture }}
                ></Avatar.Image>
              )}
              {!user.loggedIn && (
                <Avatar.Icon
                  size={42}
                  icon="account-question-outline"
                  style={{ backgroundColor: theme.colors.primaryContainer }}
                  color={theme.colors.primary}
                />
              )}
            </View>
          </View>
          <View style={{ marginHorizontal: 30, marginVertical: theme.padding }}>
            {/* <View style={{ paddingVertical: 16 }}>
              {user.userInfo?.roles &&
                user.userInfo?.roles.length > 0 &&
                user.userInfo.roles.map((role: string, index: number) => (
                  <Text key={index} variant="bodyLarge">
                    {role}
                  </Text>
                ))}
              {user.userStats && (
                <Text variant="bodyLarge">
                  Community Karma: {user.userStats.karma}
                </Text>
              )}
            </View> */}
            <Button
              mode="contained-tonal"
              onPress={user.loggedIn ? onLogout : onLogin}
            >
              {user.loggedIn ? "Log Out" : "Log In"}
            </Button>
            {/* <Button mode="contained-tonal" onPress={onLogout}>
              Log Out
            </Button> */}
          </View>
        </View>
      </ContentSurface>
      <LanguageList
        title="Search Languages"
        subtitle="Languages that are used when searching and for which translations are offered."
        langList={searchLangs}
        addLanguage={(lang: string) => {
          prefsDispatch({
            type: "ADD_SEARCH_LANG",
            payload: {
              lang,
            },
          });
        }}
        removeLanguage={(lang: string) => {
          if (searchLangs.length === 1) {
            addError("Must have at least one search language.");
            return;
          }
          prefsDispatch({
            type: "REMOVE_SEARCH_LANG",
            payload: {
              lang,
            },
          });
        }}
        onMoveUp={(index: number) => {
          prefsDispatch({
            type: "MOVE_UP_SEARCH_LANG",
            payload: {
              index: index,
            },
          });
        }}
        onMoveDown={(index: number) => {
          prefsDispatch({
            type: "MOVE_DOWN_SEARCH_LANG",
            payload: {
              index: index,
            },
          });
        }}
      />
      <LanguageList
        title="Definition Languages"
        subtitle="Languages in which definitions are shown for a given word entry."
        langList={glossLangs}
        addLanguage={(lang: string) => {
          prefsDispatch({
            type: "ADD_GLOSS_LANG",
            payload: {
              lang,
            },
          });
        }}
        removeLanguage={(lang: string) => {
          if (glossLangs.length === 1) {
            addError("Must have at least one definition language.");
            return;
          }
          prefsDispatch({
            type: "REMOVE_GLOSS_LANG",
            payload: {
              lang,
            },
          });
        }}
        onMoveUp={(index: number) => {
          prefsDispatch({
            type: "MOVE_UP_GLOSS_LANG",
            payload: {
              index: index,
            },
          });
        }}
        onMoveDown={(index: number) => {
          prefsDispatch({
            type: "MOVE_DOWN_GLOSS_LANG",
            payload: {
              index: index,
            },
          });
        }}
      />
      {/* <ContentSurface>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ flex: 5 }}>
            <Text variant="headlineMedium">Compress Results</Text>
            <Text variant="bodySmall">
              Enable this for a compressed view of the search results, to fit
              more on one screen.
            </Text>
          </View>
          <View
            style={{
              flex: 2,
              alignItems: "center",
            }}
          >
            <Switch
              value={prefsState.compressed}
              onValueChange={() => {
                prefsDispatch({ type: "TOGGLE_COMPRESSED" });
              }}
            />
          </View>
        </View>
      </ContentSurface>
      <ContentSurface>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ flex: 5 }}>
            <Text variant="headlineMedium">Colors Mode</Text>
            <Text variant="bodySmall">
              Enable this to display a unique color for each language in the
              search results.
            </Text>
          </View>
          <View
            style={{
              flex: 2,
              alignItems: "center",
            }}
          >
            <Switch
              value={prefsState.colorsMode}
              onValueChange={() => {
                prefsDispatch({ type: "TOGGLE_COLORSMODE" });
              }}
            />
          </View>
        </View>
      </ContentSurface> */}
    </ScrollView>
  );
};
