import { View } from "react-native";
import { Card, Divider, Text } from "react-native-paper";

export const SenseCard = ({ theme, navigation, word, sense, gloss }: any) => {
  return (
    <Card
      style={{
        flex: 1,
        padding: theme.padding,
        borderRadius: theme.roundness,
        marginBottom: theme.padding,
        backgroundColor: theme.colors.surfaceVariant,
      }}
      onPress={() => {
        if (navigation) {
          navigation.push("Detail", { entryID: word._id, senseID: sense._id });
        }
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Text variant="headlineMedium" style={{ flex: 6 }}>
          {word.headword.text}
        </Text>
        <Text variant="titleMedium">{word.lang}</Text>
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {word.headword.pos && (
          <Text variant="titleMedium">
            {word.headword.pos}
            {word.headword.gen && " (" + word.headword.gen + ")"}
          </Text>
        )}
      </View>
      {sense.tags && sense.tags.length > 0 && (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <Text variant="titleMedium">[</Text>
          {sense.tags &&
            sense.tags.length > 0 &&
            sense.tags.map((tag: string, index: number) => {
              return (
                <Text key={tag} variant="titleMedium">
                  {tag}
                  {index + 1 !== sense.tags.length && ", "}
                </Text>
              );
            })}
          <Text variant="titleMedium">]</Text>
        </View>
      )}
      <Divider
        style={{
          marginVertical: 4,
          backgroundColor: theme.colors.onSurface,
          maxWidth: "100%",
        }}
      />
      <View style={{ flex: 1 }}>
        {gloss && <Text variant="bodyMedium">{gloss.text}</Text>}
      </View>
    </Card>
  );
};
