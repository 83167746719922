import { StyleSheet, View } from "react-native";
import { useTheme } from "react-native-paper";
import { ThemeOverride } from "../../App";

export const ContentSurface = ({ children }: any) => {
  const theme: ThemeOverride = useTheme();
  const styles = StyleSheet.create({
    ContentSurface: {
      padding: theme.padding,
      margin: 4,
      borderWidth: 1,
      backgroundColor: theme.colors.background,
      borderColor: theme.colors.surfaceVariant,
      borderRadius: theme.roundness,
    },
  });

  return <View style={styles.ContentSurface}>{children}</View>;
};
