import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { EntryDetail } from "../components/Entry/EntryDetail";
import useEntryDetail from "../hooks/useEntryDetail";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import type { RootStackParamList } from "../types/Types";
import { useEffect } from "react";
import { useErrorBox } from "../context/errorBox";
import useEntryTranslations from "../hooks/useEntryTranslations";
import { usePreferences } from "../context/preferences";

type DetailScreenProps = NativeStackScreenProps<RootStackParamList, "Detail">;

export const DetailScreen = ({ route, navigation }: DetailScreenProps) => {
  const { addError } = useErrorBox();
  const entryID = route.params.entryID;
  const senseID = route.params.senseID;
  const { state: prefState } = usePreferences();

  const { results, isLoading, error } = useEntryDetail(entryID);

  // add errors to snackbox
  useEffect(() => {
    if (error) {
      addError(error.message);
    }
  }, [error]);

  return (
    <View style={{ flex: 1 }}>
      {isLoading && (
        <View
          style={{
            flex: 1,
            marginTop: 20,
          }}
        >
          <ActivityIndicator />
        </View>
      )}
      {results && <EntryDetail data={results} senseID={senseID} />}
    </View>
  );
};
