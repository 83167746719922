import { useEffect } from "react";
import {
  FlatList,
  GestureResponderEvent,
  SectionList,
  TouchableOpacity,
  View,
} from "react-native";
import {
  Button,
  Card,
  List,
  Surface,
  Text,
  ThemeProvider,
  useTheme,
} from "react-native-paper";
import { ThemeOverride } from "../../../App";
import { usePreferences } from "../../context/preferences";

export const SearchHistory = ({
  searchTerms,
  handlePress,
  handleDismiss,
}: {
  searchTerms: string[];
  handlePress: (term: string) => void;
  handleDismiss: () => void;
}) => {
  const theme = useTheme<ThemeOverride>();
  const renderItem = ({ item, index }: { item: string; index: number }) => (
    <HistoryItem
      term={item}
      handlePress={handlePress}
      backgroundColor={
        index % 2 ? theme.colors.deepBackground : theme.colors.background
      }
    />
  );
  return (
    <Surface elevation={1} style={{ margin: 8 }}>
      <FlatList
        keyboardShouldPersistTaps={"handled"}
        data={searchTerms}
        renderItem={renderItem}
        style={{ backgroundColor: theme.colors.surface, maxHeight: 150 }}
      ></FlatList>
      <View>
        <Button onPress={handleDismiss}>Hide Search History</Button>
      </View>
    </Surface>
  );
};

const HistoryItem = ({
  term,
  handlePress,
  backgroundColor,
}: {
  term: string;
  handlePress: (term: string) => void;
  backgroundColor: string;
}) => (
  <TouchableOpacity
    onPress={() => {
      handlePress(term);
    }}
  >
    <List.Item
      title={term}
      style={{
        backgroundColor: backgroundColor,
      }}
      left={(props) => <List.Icon {...props} icon="restore" />}
    ></List.Item>
  </TouchableOpacity>
);
