import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { List, Surface, Text } from "react-native-paper";
import { ThemeOverride } from "../../../App";
import {
  AltForm,
  AssociationInfo,
  Example as ExampleType,
  RootStackParamList,
  Sense,
} from "../../types/Types";
import { ContentSurface } from "../ContentSurface";
import { TranslationLanguageGroup } from "../Search/TranslationLanguageGroup";
import { SenseCard } from "../SenseCard";
import { Example } from "./Example";
import { InfoPanel } from "./InfoPanel";

export const EntryDetailSense = ({
  word,
  sense,
  theme,
  glossLangs,
  translations,
  index,
}: {
  word: string;
  sense: Sense;
  theme: ThemeOverride;
  glossLangs: string[];
  translations: any;
  index: number;
}) => {
  const [showTranslations, setShowTranslations] = useState<boolean>(false);
  const [showExamples, setShowExamples] = useState<boolean>(false);
  const [showOtherInfo, setShowOtherInfo] = useState<boolean>(false);

  const navigation =
    useNavigation<NativeStackScreenProps<RootStackParamList>>();

  const [senseTranslations, setSenseTranslations] = useState<any | null>(null);
  let glosses: any[];
  if (sense.glosses) {
    glosses = sense.glosses.filter((gloss: any) => {
      return glossLangs.includes(gloss.lang);
    });
  } else {
    glosses = [];
  }

  const handleSearchNavigate = (term: string) => {
    // @ts-ignore: bug with navigation types
    navigation.push("Search", { query: term });
  };

  const displayOtherInfo = () => {
    if (sense.form_of && sense.form_of.length > 0) {
      return true;
    }
    if (sense.alt_of && sense.alt_of.length > 0) {
      return true;
    }
    if (sense.derived && sense.derived.length > 0) {
      return true;
    }
    if (sense.synonyms && sense.synonyms.length > 0) {
      return true;
    }
    if (sense.antonyms && sense.antonyms.length > 0) {
      return true;
    }
    return false;
  };

  const getTranslationsForSense = () => {
    if (translations) {
      const senseTranslation = translations.find((el: any) => {
        return el.senseID === sense._id;
      });
      setSenseTranslations(senseTranslation.translations);
    }
  };

  useEffect(getTranslationsForSense, [translations]);

  return (
    <ContentSurface>
      <Text variant="headlineSmall">{index + 1})</Text>
      {/* <View style={{ flexDirection: "row", alignItems: "baseline" }}>
        {sense.regions && <Text variant="titleMedium">Region: </Text>}
        {sense.regions &&
          sense.regions.map((region: string) => {
            return (
              <Text key={region} variant="bodyMedium">
                {region}
              </Text>
            );
          })}
      </View> */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "baseline",
          flexWrap: "wrap",
        }}
      >
        {sense.tags && sense.tags.length > 0 && (
          <Text variant="titleMedium">Tags: </Text>
        )}
        {sense.tags &&
          sense.tags.length > 0 &&
          sense.tags.map((tag: string, index: number) => {
            return (
              <Text key={index} variant="bodyMedium">
                {tag}
                {index + 1 !== sense.tags?.length && ", "}
              </Text>
            );
          })}
      </View>
      <Surface style={{ margin: theme.padding, padding: theme.padding }}>
        <View style={{ marginBottom: theme.padding }}></View>
        {/* ordered by user preference */}
        {glossLangs.map((lang: string) => {
          const gloss = glosses.find((el: any) => el.lang === lang);
          if (gloss) {
            return (
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: theme.padding,
                }}
                key={gloss.lang}
              >
                <Text variant="bodyMedium" style={{ flex: 1 }}>
                  {gloss.lang}
                </Text>
                <Text variant="bodyMedium" style={{ flex: 9 }}>
                  {gloss.text}
                </Text>
              </View>
            );
          }
        })}
      </Surface>
      <Surface style={{ margin: theme.padding }}>
        {sense.examples && sense.examples.length > 0 && (
          <List.Accordion
            expanded={showExamples}
            title={"Examples"}
            onPress={() => {
              setShowExamples(!showExamples);
            }}
            style={{
              borderBottomColor: theme.colors.outlineVariant,
              borderBottomWidth: showExamples ? 1 : 0,
              marginBottom: showExamples ? theme.padding : 0,
            }}
          >
            {
              <View
                style={{
                  borderBottomColor: theme.colors.outlineVariant,
                  borderBottomWidth: showExamples ? 1 : 0,
                }}
              >
                {sense.examples.map((example: ExampleType, index: number) => {
                  return (
                    <Example
                      data={example}
                      index={index + 1}
                      isLast={index + 1 === sense.examples?.length}
                      word={word}
                      key={index}
                    />
                  );
                })}
              </View>
            }
          </List.Accordion>
        )}
        {displayOtherInfo() && (
          <List.Accordion
            expanded={showOtherInfo}
            title={"Other Info"}
            onPress={() => {
              setShowOtherInfo(!showOtherInfo);
            }}
            style={{
              borderBottomColor: theme.colors.outlineVariant,
              borderBottomWidth: showOtherInfo ? 1 : 0,
              marginBottom: showOtherInfo ? theme.padding : 0,
            }}
          >
            <View
              style={{
                borderBottomColor: theme.colors.outlineVariant,
                borderBottomWidth: showOtherInfo ? 1 : 0,
              }}
            >
              {sense.alt_of && sense.alt_of.length > 0 && (
                <InfoPanel>
                  <Text>Alternate form of: </Text>
                  {sense.alt_of.map((alt: AltForm, index: number) => {
                    return (
                      <Text
                        onPress={() => handleSearchNavigate(alt.word as string)}
                        style={{ textDecorationLine: "underline" }}
                        key={index}
                      >
                        {alt.word}
                      </Text>
                    );
                  })}
                </InfoPanel>
              )}
              {sense.form_of && sense.form_of.length > 0 && (
                <InfoPanel>
                  <Text>Form of: </Text>
                  {sense.form_of.map((form: AltForm, index: number) => {
                    return (
                      <Text
                        key={index}
                        onPress={() =>
                          handleSearchNavigate(form.word as string)
                        }
                        style={{ textDecorationLine: "underline" }}
                      >
                        {form.word}
                      </Text>
                    );
                  })}
                </InfoPanel>
              )}
              {sense.derived && sense.derived.length > 0 && (
                <InfoPanel>
                  <Text>Derivations: </Text>
                  {sense.derived?.map((der: AssociationInfo, index: number) => {
                    return (
                      <Text
                        key={index}
                        onPress={() => handleSearchNavigate(der.word as string)}
                        style={{ textDecorationLine: "underline" }}
                      >
                        {der.word}
                        {der.tags?.map((tag: string, index: number) => (
                          <Text key={index}> ({tag})</Text>
                        ))}
                        {index + 1 !== sense.derived?.length && ", "}
                      </Text>
                    );
                  })}
                </InfoPanel>
              )}
              {sense.synonyms && sense.synonyms.length > 0 && (
                <InfoPanel>
                  <Text>Synonyms: </Text>
                  {sense.synonyms?.map(
                    (syn: AssociationInfo, index: number) => {
                      return (
                        <Text
                          key={index}
                          onPress={() =>
                            handleSearchNavigate(syn.word as string)
                          }
                          style={{ textDecorationLine: "underline" }}
                        >
                          {syn.word}
                          {syn.tags?.map((tag: string, index: number) => (
                            <Text key={index}> ({tag})</Text>
                          ))}
                          {index + 1 !== sense.synonyms?.length && ", "}
                        </Text>
                      );
                    }
                  )}
                </InfoPanel>
              )}
              {sense.antonyms && sense.antonyms.length > 0 && (
                <InfoPanel>
                  <Text>Antonyms: </Text>
                  {sense.antonyms?.map(
                    (ant: AssociationInfo, index: number) => {
                      return (
                        <Text
                          key={index}
                          onPress={() =>
                            handleSearchNavigate(ant.word as string)
                          }
                          style={{ textDecorationLine: "underline" }}
                        >
                          {ant.word}
                          {ant.tags?.map((tag: string, index: number) => (
                            <Text key={index}> ({tag})</Text>
                          ))}
                          {index + 1 !== sense.antonyms?.length && ", "}
                        </Text>
                      );
                    }
                  )}
                </InfoPanel>
              )}
            </View>
          </List.Accordion>
        )}
        <List.Accordion
          expanded={showTranslations}
          title={"Translations"}
          onPress={() => {
            setShowTranslations(!showTranslations);
          }}
          style={{
            borderBottomColor: theme.colors.outlineVariant,
            borderBottomWidth: showTranslations ? 1 : 0,
            marginBottom: showTranslations ? theme.padding : 0,
          }}
        >
          {senseTranslations && (
            <View>
              {Object.keys(senseTranslations).map((lang) => {
                return (
                  <TranslationLanguageGroup
                    lang={lang}
                    translations={senseTranslations[lang]}
                    key={`${lang}`}
                    scrollToPos={() => {}}
                  />
                );
              })}
            </View>
          )}
        </List.Accordion>
      </Surface>
    </ContentSurface>
  );
};
