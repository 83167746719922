import { useState } from "react";
import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  NativeTouchEvent,
  View,
} from "react-native";
import { Divider, List, Surface, Text, useTheme } from "react-native-paper";
import { ThemeOverride } from "../../../App";
import { useLanguages } from "../../context/languages";
import { usePreferences } from "../../context/preferences";
import { TranslationItem } from "./TranslationItem";

export const TranslationLanguageGroup = ({
  lang,
  translations,
  scrollToPos,
  index,
}: any) => {
  const { state: userPrefs, dispatch: userPrefsDispatch } = usePreferences();
  const { languageNames, languageColors } = useLanguages();
  const langColor = userPrefs.colorsMode
    ? languageColors[lang].primary
    : undefined;
  const langBackgroundColor = userPrefs.colorsMode
    ? languageColors[lang].elevation.level1
    : undefined;
  return (
    <View>
      <List.Accordion
        title={languageNames[lang].native}
        titleStyle={{
          color: userPrefs.expanded[lang] ? "langColor" : "black",
        }}
        left={(props) => (
          <List.Icon
            color={langColor ? langColor : props.color}
            icon={
              userPrefs.expanded[lang]
                ? "checkbox-blank-circle"
                : "checkbox-blank-circle-outline"
            }
            style={{
              marginVertical: userPrefs.compressed ? -3 : 0,
              marginLeft: 8,
            }}
          />
        )}
        expanded={userPrefs.expanded[lang] ? true : false}
        onPress={(): void => {
          userPrefsDispatch({
            type: "TOGGLE_EXPANSION",
            payload: {
              lang,
            },
          });
          scrollToPos(index);
        }}
        style={{
          paddingLeft: 0,
          paddingVertical: userPrefs.compressed ? 0 : undefined,
          marginVertical: userPrefs.compressed ? -3 : 0,
        }}
      >
        {translations &&
          translations.map((item: any) => {
            return (
              <TranslationItem
                key={item.s}
                item={item}
                backgroundColor={langBackgroundColor}
              />
            );
          })}
      </List.Accordion>
    </View>
  );
};
