import { useNavigation } from "@react-navigation/native";
import { useState, useEffect } from "react";
import { Searchbar, Text } from "react-native-paper";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import type { RootStackParamList, SearchBarProps } from "../types/Types";
import { usePreferences } from "../context/preferences";
import {
  View,
  Keyboard,
  TouchableWithoutFeedback,
  TextInput,
  Platform,
} from "react-native";
import { SearchHistory } from "./Search/SearchHistory";

export const SearchBar = (props: SearchBarProps) => {
  const [searchQuery, setSearchQuery] = useState(
    props.searchQuery ? props.searchQuery : ""
  );
  const onChangeSearch = (query: string) => {
    setSearchQuery(query);
  };
  const navigation =
    useNavigation<NativeStackScreenProps<RootStackParamList>>();
  const { state, dispatch } = usePreferences();
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const onSubmit = () => {
    const trimmedQuery = searchQuery.trim();
    if (!trimmedQuery) return;
    // @ts-ignore: Some bug with RN > 0.65 (https://stackoverflow.com/questions/68667766/react-native-typescript-string-is-not-assignable-to-parameter-of-type-never)
    navigation.navigate("Search", { query: trimmedQuery });
    setSearchQuery(trimmedQuery);
    dispatch({
      type: "ADD_SEARCH_HISTORY",
      payload: {
        searchTerm: trimmedQuery,
      },
    });
    setShowHistory(false);
  };

  const handleHistoryPress = (term: string) => {
    // @ts-ignore: Some bug with RN > 0.65 (https://stackoverflow.com/questions/68667766/react-native-typescript-string-is-not-assignable-to-parameter-of-type-never)
    navigation.navigate("Search", { query: term });
    setSearchQuery(term);
    Keyboard.dismiss();
    setShowHistory(false);
  };

  const handleDismiss = () => {
    setShowHistory(false);
  };

  useEffect(() => {
    setSearchQuery(props.searchQuery ? props.searchQuery : searchQuery);
  }, [props.searchQuery]);

  return (
    <View>
      <Searchbar
        loading={props.isLoading}
        placeholder="Search"
        onChangeText={onChangeSearch}
        // react native web currently has a bug (only on Android) that prevents
        // onSubmitEditing from firing when text is present in an InputArea
        onKeyPress={(e) => {
          if (e.nativeEvent.key === "Enter") {
            onSubmit();
          }
        }}
        value={searchQuery}
        autoCapitalize="none"
        onFocus={() => {
          setShowHistory(true);
        }}
        onEndEditing={() => {
          setShowHistory(false);
        }}
        autoCorrect={false}
        returnKeyType={"search"}
        enablesReturnKeyAutomatically={true}
      />
      {showHistory && (
        <SearchHistory
          handlePress={handleHistoryPress}
          handleDismiss={handleDismiss}
          searchTerms={state.searchHistory}
        />
      )}
    </View>
  );
};
