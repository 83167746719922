import { useState } from "react";
import { TouchableHighlight, TouchableOpacity, View } from "react-native";
import {
  Divider,
  Text,
  useTheme,
  Modal,
  Portal,
  Button,
  Provider,
  Card,
  Chip,
  TouchableRipple,
} from "react-native-paper";
import { ThemeOverride } from "../../../App";
import type { Example as ExampleType } from "../../types/Types";

export const Example = ({
  data,
  index,
  word,
  isLast,
}: {
  data: ExampleType;
  index: number;
  word: string;
  isLast: boolean;
}) => {
  const { text, ref, english, type, roman, note } = data;
  const highlightedText = text?.split(word);
  const theme: ThemeOverride = useTheme();
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Portal>
        <Modal
          visible={showModal}
          onDismiss={() => setShowModal(false)}
          style={{
            backgroundColor: "rgba(0, 0, 0, .02)",
          }}
        >
          <Card style={{ margin: theme.padding, padding: theme.padding }}>
            <Text variant="titleLarge">
              "
              {highlightedText?.map((textChunk: string, index: number) => {
                if (index + 1 !== highlightedText?.length) {
                  return (
                    <Text variant="titleLarge" key={index}>
                      {textChunk}
                      <Text
                        style={{
                          color: theme.colors.primary,
                          fontWeight: "bold",
                        }}
                      >
                        {word}
                      </Text>
                    </Text>
                  );
                } else {
                  return (
                    <Text variant="titleLarge" key={index}>
                      {textChunk}
                    </Text>
                  );
                }
              })}
              "
            </Text>
            {ref && (
              <View>
                <Divider style={{ marginVertical: 16 }} />
                {ref && <Text variant="bodyMedium">{ref}</Text>}
              </View>
            )}
          </Card>
        </Modal>
      </Portal>
      <View
        style={{
          backgroundColor: theme.colors.background,
          padding: theme.padding,
        }}
      >
        <TouchableOpacity onPress={() => setShowModal(true)}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Text variant="labelMedium" style={{ flex: 1 }}>
              {index})
            </Text>
            <Text style={{ flex: 9 }}>
              {highlightedText?.map((textChunk: string, index: number) => {
                if (index + 1 !== highlightedText?.length) {
                  return (
                    <Text variant="bodyMedium" key={index}>
                      {textChunk}
                      <Text
                        style={{
                          color: theme.colors.primary,
                          fontWeight: "bold",
                        }}
                      >
                        {word}
                      </Text>
                    </Text>
                  );
                } else {
                  return (
                    <Text variant="bodyMedium" key={index}>
                      {textChunk}
                    </Text>
                  );
                }
              })}
            </Text>
          </View>
        </TouchableOpacity>
        {!isLast && <Divider style={{ marginTop: theme.padding * 2 }} />}
      </View>
    </>
  );
};
