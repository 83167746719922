import { preferencesStateType } from "./context";

type ActionType = {
  type: string;
  payload?: {
    lang?: string;
    index?: number;
    initialState?: preferencesStateType;
    searchTerm?: string;
  };
};

function arrayMove(array: any[], fromIndex: number, toIndex: number) {
  const newArray = JSON.parse(JSON.stringify(array));
  const element = array[fromIndex];
  newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, element);
  return newArray;
}

export const preferencesReducer = (
  state: preferencesStateType,
  action: ActionType
): preferencesStateType => {
  switch (action.type) {
    case "LOAD_INITIAL_STATE": {
      if (!action.payload || !action.payload.initialState) return state;
      return action.payload.initialState;
    }
    case "ADD_SEARCH_LANG":
      if (!action.payload || !action.payload.lang) return state;
      return {
        ...state,
        searchLangs: Array.from(
          new Set([...state.searchLangs, action.payload?.lang])
        ),
        expanded: {
          ...state.expanded,
          [action.payload.lang]: true,
        },
      };
    case "ADD_GLOSS_LANG":
      if (!action.payload || !action.payload.lang) return state;
      return {
        ...state,
        glossLangs: Array.from(
          new Set([...state.glossLangs, action.payload?.lang])
        ),
      };
    case "REMOVE_SEARCH_LANG":
      if (!action.payload || !action.payload.lang) return state;
      return {
        ...state,
        searchLangs: state.searchLangs.filter(
          (lang: string) => lang !== action.payload?.lang
        ),
      };
    case "REMOVE_GLOSS_LANG":
      if (!action.payload || !action.payload.lang) return state;
      return {
        ...state,
        glossLangs: state.glossLangs.filter(
          (lang: string) => lang !== action.payload?.lang
        ),
      };
    case "MOVE_UP_SEARCH_LANG":
      if (
        !action.payload ||
        !action.payload.index ||
        action.payload.index === 0
      )
        return state;
      return {
        ...state,
        searchLangs: arrayMove(
          state.searchLangs,
          action.payload.index,
          action.payload.index - 1
        ),
      };
    case "MOVE_DOWN_SEARCH_LANG":
      if (
        !action.payload ||
        action.payload.index === undefined ||
        action.payload.index === state.searchLangs.length
      )
        return state;
      return {
        ...state,
        searchLangs: arrayMove(
          state.searchLangs,
          action.payload.index,
          action.payload.index + 1
        ),
      };
    case "MOVE_UP_GLOSS_LANG":
      if (
        !action.payload ||
        !action.payload.index ||
        action.payload.index === 0
      )
        return state;
      return {
        ...state,
        glossLangs: arrayMove(
          state.glossLangs,
          action.payload.index,
          action.payload.index - 1
        ),
      };
    case "MOVE_DOWN_GLOSS_LANG":
      if (
        !action.payload ||
        action.payload.index === undefined ||
        action.payload.index === state.glossLangs.length
      )
        return state;
      return {
        ...state,
        glossLangs: arrayMove(
          state.glossLangs,
          action.payload.index,
          action.payload.index + 1
        ),
      };
    case "TOGGLE_EXPANSION":
      if (!action.payload || !action.payload.lang) return state;
      return {
        ...state,
        expanded: {
          ...state.expanded,
          [action.payload.lang]: !state.expanded[action.payload.lang],
        },
      };
    case "TOGGLE_COMPRESSED":
      return {
        ...state,
        compressed: !state.compressed,
      };
    case "TOGGLE_COLORSMODE":
      return {
        ...state,
        colorsMode: !state.colorsMode,
      };
    case "ADD_SEARCH_HISTORY":
      if (!action.payload || !action.payload.searchTerm) return state;
      return {
        ...state,
        // dedupe and limit to 15
        searchHistory: [
          ...new Set([
            action.payload.searchTerm,
            ...state.searchHistory.slice(0, 14),
          ]),
        ],
      };
    default:
      return state;
  }
};
