import { createContext, ReactNode, useContext } from "react";

const initialState = {
  availableLanguages: ["en", "es", "de", "it", "ru", "pt", "fr"],
  languageNames: {
    en: {
      native: "English",
      en: "English",
    },
    es: {
      native: "Español",
      en: "Spanish",
    },
    de: {
      native: "Deutsch",
      en: "German",
    },
    it: {
      native: "Italiano",
      en: "Italian",
    },
    ru: {
      native: "Русский",
      en: "Russian",
    },
    pt: {
      native: "Português",
      en: "Portuguese",
    },
    fr: {
      native: "Français",
      en: "French",
    },
  },
  languageColors: {
    en: {
      primary: "rgb(0, 96, 169)",
      onPrimary: "rgb(255, 255, 255)",
      primaryContainer: "rgb(211, 228, 255)",
      onPrimaryContainer: "rgb(0, 28, 56)",
      elevation: {
        level0: "transparent",
        level1: "rgb(240, 244, 251)",
        level2: "rgb(233, 240, 248)",
        level3: "rgb(225, 235, 246)",
        level4: "rgb(223, 233, 245)",
        level5: "rgb(218, 230, 243)",
      },
    },
    es: {
      primary: "rgb(115, 92, 0)",
      onPrimary: "rgb(255, 255, 255)",
      primaryContainer: "rgb(255, 224, 132)",
      onPrimaryContainer: "rgb(35, 27, 0)",
      elevation: {
        level0: "transparent",
        level1: "rgb(248, 243, 242)",
        level2: "rgb(244, 238, 235)",
        level3: "rgb(240, 234, 227)",
        level4: "rgb(238, 232, 224)",
        level5: "rgb(235, 229, 219)",
      },
    },
    de: {
      primary: "rgb(180, 39, 29)",
      onPrimary: "rgb(255, 255, 255)",
      primaryContainer: "rgb(255, 218, 213)",
      onPrimaryContainer: "rgb(65, 0, 0)",
      elevation: {
        level0: "transparent",
        level1: "rgb(251, 240, 244)",
        level2: "rgb(249, 234, 237)",
        level3: "rgb(247, 228, 230)",
        level4: "rgb(246, 226, 228)",
        level5: "rgb(245, 221, 223)",
      },
    },
    it: {
      primary: "rgb(0, 107, 85)",
      onPrimary: "rgb(255, 255, 255)",
      primaryContainer: "rgb(109, 250, 210)",
      onPrimaryContainer: "rgb(0, 32, 24)",
      elevation: {
        level0: "transparent",
        level1: "rgb(238, 246, 242)",
        level2: "rgb(231, 241, 237)",
        level3: "rgb(223, 237, 232)",
        level4: "rgb(221, 236, 230)",
        level5: "rgb(216, 233, 227)",
      },
    },
    ru: {
      primary: "rgb(88, 71, 210)",
      onPrimary: "rgb(255, 255, 255)",
      primaryContainer: "rgb(228, 223, 255)",
      onPrimaryContainer: "rgb(22, 0, 102)",
      elevation: {
        level0: "transparent",
        level1: "rgb(247, 242, 253)",
        level2: "rgb(242, 237, 251)",
        level3: "rgb(237, 231, 250)",
        level4: "rgb(235, 229, 250)",
        level5: "rgb(232, 226, 249)",
      },
    },
    pt: {
      primary: "rgb(181, 17, 107)",
      onPrimary: "rgb(255, 255, 255)",
      primaryContainer: "rgb(255, 217, 228)",
      onPrimaryContainer: "rgb(62, 0, 33)",
      elevation: {
        level0: "transparent",
        level1: "rgb(251, 239, 248)",
        level2: "rgb(249, 232, 243)",
        level3: "rgb(247, 225, 239)",
        level4: "rgb(246, 223, 237)",
        level5: "rgb(245, 218, 234)",
      },
    },
    fr: {
      primary: "rgb(156, 67, 46)",
      onPrimary: "rgb(255, 255, 255)",
      primaryContainer: "rgb(255, 218, 210)",
      onPrimaryContainer: "rgb(61, 6, 0)",
      elevation: {
        level0: "transparent",
        level1: "rgb(250, 242, 245)",
        level2: "rgb(247, 236, 238)",
        level3: "rgb(244, 231, 232)",
        level4: "rgb(243, 229, 230)",
        level5: "rgb(241, 225, 226)",
      },
    },
  },
};

type LangugagesStateType = {
  availableLanguages: string[];
  languageNames: {
    [key: string]: {
      native: string;
      en: string;
    };
  };
  languageColors: {
    [key: string]: any;
  };
};

const LanguagesContext = createContext<LangugagesStateType>(initialState);

export const useLanguages = () => useContext(LanguagesContext);

export const LanguagesContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <LanguagesContext.Provider value={initialState}>
      {children}
    </LanguagesContext.Provider>
  );
};
