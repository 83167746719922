import * as React from "react";
import { FlatList, TouchableOpacity, View } from "react-native";
import { Button, Text } from "react-native-paper";
import { SearchBar } from "../components/SearchBar";

export const HomeScreen = ({ navigation }: any) => {
  const [searchQuery, setSearchQuery] = React.useState("");

  const onChangeSearch = (query: string) => setSearchQuery(query);

  return (
    <View>
      <SearchBar />
      <Button onPress={() => navigation.navigate("Help")}>Need Help?</Button>
    </View>
  );
};
