import { View } from "react-native";
import { Divider, Text, useTheme } from "react-native-paper";
import { ThemeOverride } from "../../../App";

export const InfoPanel = ({ children }: any) => {
  const theme = useTheme<ThemeOverride>();
  return (
    <View
      style={{
        backgroundColor: theme.colors.background,
        padding: theme.padding,
        paddingLeft: theme.padding * 2,
        paddingBottom: theme.padding * 2,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {children}
      </View>
      {/* <Divider style={{ marginTop: theme.padding * 2 }} /> */}
    </View>
  );
};
