import * as React from "react";
import { StyleSheet, View, Platform } from "react-native";
import {
  Provider as PaperProvider,
  MD3LightTheme as DefaultTheme,
} from "react-native-paper";
import TopBar from "./src/components/TopBar";
import {
  NavigationContainer,
  DefaultTheme as DefaultNavTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SearchScreen } from "./src/screens/SearchScreen";
import { DetailScreen } from "./src/screens/DetailScreen";
import { HomeScreen } from "./src/screens/HomeScreen";
import { RootStackParamList } from "./src/types/Types";
import { PreferencesProvider } from "./src/context/preferences";
import { ErrorBoxProvider } from "./src/context/errorBox";
import { ErrorBox } from "./src/components/ErrorBox";
import { PreferencesScreen } from "./src/screens/PreferencesScreen";
import { LanguagesContextProvider } from "./src/context/languages";
import { HelpScreen } from "./src/screens/HelpScreen";
import { UserProvider } from "./src/context/user/index";

const theme = {
  ...DefaultTheme,
  colors: {
    // ...DefaultTheme.colors,
    primary: "rgb(85, 85, 169)",
    onPrimary: "rgb(255, 255, 255)",
    primaryContainer: "rgb(226, 223, 255)",
    onPrimaryContainer: "rgb(14, 6, 100)",
    secondary: "rgb(93, 92, 113)",
    onSecondary: "rgb(255, 255, 255)",
    secondaryContainer: "rgb(226, 224, 249)",
    onSecondaryContainer: "rgb(26, 26, 44)",
    tertiary: "rgb(121, 83, 105)",
    onTertiary: "rgb(255, 255, 255)",
    tertiaryContainer: "rgb(255, 216, 235)",
    onTertiaryContainer: "rgb(47, 17, 36)",
    error: "rgb(186, 26, 26)",
    onError: "rgb(255, 255, 255)",
    errorContainer: "rgb(255, 218, 214)",
    onErrorContainer: "rgb(65, 0, 2)",
    background: "rgb(255, 251, 255)",
    deepBackground: "rgb(242, 242, 242)",
    onBackground: "rgb(28, 27, 31)",
    surface: "rgb(255, 251, 255)",
    onSurface: "rgb(28, 27, 31)",
    surfaceVariant: "rgb(228, 225, 236)",
    onSurfaceVariant: "rgb(71, 70, 79)",
    outline: "rgb(119, 118, 128)",
    outlineVariant: "rgb(200, 197, 208)",
    shadow: "rgb(0, 0, 0)",
    scrim: "rgb(0, 0, 0)",
    inverseSurface: "rgb(49, 48, 52)",
    inverseOnSurface: "rgb(243, 239, 244)",
    inversePrimary: "rgb(194, 193, 255)",
    elevation: {
      level0: "transparent",
      level1: "rgb(247, 243, 251)",
      level2: "rgb(241, 238, 248)",
      level3: "rgb(236, 233, 246)",
      level4: "rgb(235, 231, 245)",
      level5: "rgb(231, 228, 243)",
    },
    surfaceDisabled: "rgba(28, 27, 31, 0.12)",
    onSurfaceDisabled: "rgba(28, 27, 31, 0.38)",
    backdrop: "rgba(48, 48, 56, 0.4)",
    adding: "#4caf5080",
    removing: "#ef535080",
  },
  padding: 8,
};

const NavTheme = {
  ...DefaultNavTheme,
  colors: {
    ...DefaultNavTheme.colors,
    background: "rgb(242, 242, 242)",
  },
};

export type ThemeOverride = typeof theme;

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function App() {
  return (
    <PreferencesProvider>
      <UserProvider>
        <LanguagesContextProvider>
          <PaperProvider theme={theme}>
            <ErrorBoxProvider>
              <View style={styles.outerContainer}>
                <View style={styles.innerContainer}>
                  <NavigationContainer theme={NavTheme}>
                    <Stack.Navigator
                      initialRouteName="Home"
                      screenOptions={{
                        header: (props) => <TopBar {...props} />,
                      }}
                    >
                      <Stack.Screen
                        name="Home"
                        component={HomeScreen}
                        options={{ title: "My home" }}
                      />
                      <Stack.Screen
                        name="Search"
                        component={SearchScreen}
                        options={{ title: "My search" }}
                      />
                      <Stack.Screen
                        name="Detail"
                        component={DetailScreen}
                        options={{ title: "My detail" }}
                      />
                      <Stack.Screen
                        name="Preferences"
                        component={PreferencesScreen}
                        options={{ title: "My preferences" }}
                      />
                      <Stack.Screen
                        name="Help"
                        component={HelpScreen}
                        options={{ title: "My help screen" }}
                      />
                    </Stack.Navigator>
                    <ErrorBox />
                  </NavigationContainer>
                </View>
              </View>
            </ErrorBoxProvider>
          </PaperProvider>
        </LanguagesContextProvider>
      </UserProvider>
    </PreferencesProvider>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    ...Platform.select({
      native: {},
      default: {
        backgroundColor: theme.colors.deepBackground,
      },
    }),
  },
  innerContainer: {
    flex: 1,
    ...Platform.select({
      native: {},
      default: {
        width: "100%",
        maxWidth: 800,
        marginLeft: "auto",
        marginRight: "auto",
      },
    }),
  },
});
