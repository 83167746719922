import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useEffect, useRef, useState } from "react";
import { FlatList, ScrollView, View } from "react-native";
import { Divider, IconButton, List, Text, useTheme } from "react-native-paper";
import { ThemeOverride } from "../../../App";
import { usePreferences } from "../../context/preferences";
import useEntryTranslations from "../../hooks/useEntryTranslations";
import { Entry, RootStackParamList } from "../../types/Types";
import { EntryDetailSense } from "./EntryDetailSense";
import { Audio } from "expo-av";

export const EntryDetail = ({
  data,
  senseID,
}: {
  data: Entry;
  senseID: string | undefined;
}) => {
  const theme: ThemeOverride = useTheme();
  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { state: prefState } = usePreferences();
  const scrollViewRef = useRef<FlatList | null>(null);

  const scrollToPos = (index: number) => {
    setTimeout(() => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToIndex({
          index: index,
          viewPosition: 0,
        });
      }
      // enough time for render to complete and new index location to be registered
    }, 250);
  };

  const [sound, setSound] = useState<any | null>(null);

  const glossLangs = prefState.glossLangs;

  const { results: translations } = useEntryTranslations(
    data._id,
    prefState.searchLangs
  );

  const playSound = async (mp3_url: string) => {
    // console.log("loading sound", mp3_url);
    await Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
    });
    const { sound } = await Audio.Sound.createAsync({ uri: mp3_url });
    setSound(sound);

    // console.log("Playing Sound");
    await sound.playAsync();
  };

  // scroll to sense on component mount, if senseID is present
  useEffect(() => {
    if (senseID) {
      for (const sense of data.senses) {
        if (String(sense._id) === senseID) {
          const index = data.senses.indexOf(sense);
          // don't scroll if it's the first sense
          if (index !== 0) {
            scrollToPos(data.senses.indexOf(sense));
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    return sound
      ? () => {
          // console.log("Unloading Sound");
          sound.unloadAsync();
        }
      : undefined;
  }, [sound]);

  const renderItem = ({ item, index }: any) => {
    return (
      <EntryDetailSense
        key={item._id}
        theme={theme}
        word={data.headword.text}
        sense={item}
        glossLangs={glossLangs}
        translations={translations}
        index={index}
      />
    );
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <View
        style={{
          padding: 8,
          justifyContent: "flex-start",
          borderWidth: 1,
          borderColor: theme.colors.surfaceVariant,
          borderRadius: theme.roundness,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flex: 8 }}>
            <Text variant="displayMedium">{data.headword.text}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text variant="titleMedium">{data.lang}</Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Text variant="titleMedium">
            {data.headword.pos}
            {data.headword.gender && " (" + data.headword.gender + ")"}
          </Text>
        </View>
      </View>
      {/* <ScrollView> */}
      <FlatList
        ListHeaderComponent={() => (
          <EntryHeader
            data={data}
            theme={theme}
            sound={sound}
            setSound={setSound}
            playSound={playSound}
          />
        )}
        data={data.senses}
        renderItem={renderItem}
        ref={scrollViewRef}
      />
      {/* </ScrollView> */}
    </View>
  );
};

const EntryHeader = ({ data, theme, sound, setSound, playSound }: any) => {
  const [showEtymology, setShowEtymology] = useState<boolean>(false);
  const [showForms, setShowForms] = useState<boolean>(false);
  const [showSounds, setShowSounds] = useState<boolean>(false);

  return (
    <View>
      <View style={{ marginBottom: 8 }}>
        {data.etymology && (
          <View>
            <List.Accordion
              expanded={showEtymology}
              title={"Etymology"}
              onPress={() => {
                setShowEtymology(!showEtymology);
              }}
              style={{ backgroundColor: theme.colors.deepBackground }}
            >
              <Text variant="titleMedium" style={{ marginHorizontal: 16 }}>
                {data.etymology}
              </Text>
            </List.Accordion>
          </View>
        )}
        {data.forms && data.forms.length > 0 && (
          <View>
            <List.Accordion
              expanded={showForms}
              title={"Forms"}
              onPress={() => {
                setShowForms(!showForms);
              }}
              style={{ backgroundColor: theme.colors.deepBackground }}
            >
              <View style={{ marginHorizontal: 16 }}>
                {data.forms.map(({ form, tags }: any, index: number) => {
                  return (
                    <View key={index} style={{ flexDirection: "row" }}>
                      {tags.map((tag: string, index: number) => {
                        if (index + 1 !== tags.length) {
                          return (
                            <Text variant="titleMedium" key={index}>
                              {tag},{" "}
                            </Text>
                          );
                        } else {
                          return (
                            <Text variant="titleMedium" key={index}>
                              {tag}:{" "}
                            </Text>
                          );
                        }
                      })}
                      <Text variant="titleMedium">{form}</Text>
                    </View>
                  );
                })}
              </View>
            </List.Accordion>
          </View>
        )}
        {data.sounds && data.sounds.length > 0 && (
          <View>
            <List.Accordion
              expanded={showSounds}
              title={"Pronunciation"}
              onPress={() => {
                setShowSounds(!showSounds);
              }}
              style={{ backgroundColor: theme.colors.deepBackground }}
            >
              <View style={{ marginHorizontal: 16 }}>
                {data.sounds.map((sound: any, index: number) => {
                  return (
                    <View
                      key={index}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {sound.tags &&
                        sound.tags.map((tag: string, index: number) => {
                          if (index + 1 !== sound.tags.length) {
                            return (
                              <Text variant="titleMedium" key={index}>
                                {tag},{" "}
                              </Text>
                            );
                          } else {
                            return (
                              <Text variant="titleMedium" key={index}>
                                {tag}:{" "}
                              </Text>
                            );
                          }
                        })}
                      {sound.ipa && <Text>{sound.ipa}</Text>}
                      {sound.mp3_url && (
                        <IconButton
                          size={12}
                          icon={"play"}
                          mode="contained"
                          onPress={() => {
                            playSound(sound.mp3_url);
                          }}
                        />
                      )}
                    </View>
                  );
                })}
              </View>
            </List.Accordion>
          </View>
        )}
      </View>
    </View>
  );
};
