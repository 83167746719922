import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useState } from "react";
import { LayoutChangeEvent, StyleSheet, View } from "react-native";
import {
  Card,
  Divider,
  List,
  Surface,
  Text,
  useTheme,
} from "react-native-paper";
import { ThemeOverride } from "../../../App";
import { usePreferences } from "../../context/preferences";
import { RootStackParamList } from "../../types/Types";
import { ContentSurface } from "../ContentSurface";
import { SenseCard } from "../SenseCard";
import { TranslationLanguageGroup } from "./TranslationLanguageGroup";

export const SenseItem = ({ word, scrollToPos, index }: any) => {
  const theme: ThemeOverride = useTheme();
  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { state: prefState } = usePreferences();

  let gloss;

  if (word.sense.glosses) {
    // show gloss in same language as word
    gloss = word.sense.glosses.find((gloss: any) => {
      return gloss.lang === word.lang;
    });
    // if no gloss is available, cycle through user-defined glossLangs
    // until one is found
    if (!gloss) {
      for (const glossLang of prefState.glossLangs) {
        gloss = word.sense.glosses.find((gloss: any) => {
          return gloss.lang === glossLang;
        });
        if (gloss) break;
      }
    }
  }

  // if a word's only translation is itself, don't show
  let translationLangKeys = Object.keys(word.translations);
  translationLangKeys = translationLangKeys.filter((langKey: string) => {
    if (langKey === word.lang) {
      if (word.translations[langKey].length < 2) {
        return false;
      }
    }
    return true;
  });

  return (
    <ContentSurface>
      <List.Section>
        <SenseCard
          theme={theme}
          navigation={navigation}
          word={word}
          sense={word.sense}
          gloss={gloss}
        />
        {translationLangKeys && (
          <Surface elevation={1}>
            {translationLangKeys.map((lang) => {
              return (
                <TranslationLanguageGroup
                  lang={lang}
                  translations={word.translations[lang]}
                  key={`${word.sense._id}+${lang}`}
                  scrollToPos={scrollToPos}
                  index={index}
                />
              );
            })}
          </Surface>
        )}
      </List.Section>
    </ContentSurface>
  );
};
