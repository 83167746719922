import { Result } from "../types/Types";

export const API_URL = "https://staging.polydict.xyz/.netlify/functions/";
// export const API_URL = "http://localhost:4001/.netlify/functions/";

export const makeAPIRequest = async (
  requestString: string
): Promise<Result<unknown>> => {
  const url = `${API_URL}${requestString}`;

  const fetchResult = fetch(url)
    .then(async (response: Response) => {
      if (response.ok) {
        const results = await response.json();
        if (results[0] || results._id) {
          return { ok: <const>true, value: results };
        } else {
          return {
            ok: <const>false,
            error: new Error(`No results for term in selected languages.`),
          };
        }
      } else {
        const { message } = await response.json();
        return { ok: <const>false, error: new Error(message) };
      }
    })
    .catch((err: Error) => {
      return { ok: <const>false, error: err };
    });

  return fetchResult;
};

export const getEntryDetail = async (
  langIn: string[],
  langOut: string[],
  term: string
): Promise<Result<unknown>> => {
  const url = `http://127.0.0.1:4000/api/search?term=${term}&langs_in=${langIn}&langs_out=${langOut}`;
  // console.log(url);

  const fetchResult = fetch(url)
    .then(async (response: Response) => {
      if (response.ok) {
        const results = await response.json();
        // console.log(results);
        return { ok: <const>true, value: results };
      } else {
        const { message } = await response.json();
        return { ok: <const>false, error: new Error(message) };
      }
    })
    .catch((err: Error) => {
      return { ok: <const>false, error: err };
    });

  return fetchResult;
};
