import { useEffect, useState } from "react";
import { View } from "react-native";
import { Button, Snackbar, Text } from "react-native-paper";
import { useErrorBox } from "../context/errorBox";

export const ErrorBox = () => {
  const { errorMessage, errorVisible, clearError, addError } = useErrorBox();
  const [visible, setVisible] = useState(false);

  const onDismissSnackBar = () => {
    clearError();
  };

  return (
    <View>
      <Snackbar
        visible={errorVisible}
        onDismiss={onDismissSnackBar}
        action={{
          label: "Dismiss",
          onPress: () => {
            // Do something
          },
        }}
      >
        {errorMessage}
      </Snackbar>
    </View>
  );
};
