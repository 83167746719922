import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { View } from "react-native";
import { Divider, List, Text, useTheme } from "react-native-paper";
import { ThemeOverride } from "../../../App";
import { usePreferences } from "../../context/preferences";
import { RootStackParamList } from "../../types/Types";

export const TranslationItem = ({ item, backgroundColor }: any) => {
  const theme: ThemeOverride = useTheme();
  const { state: userPrefs } = usePreferences();
  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  return (
    <View>
      <List.Item
        title={`${item.text}${item.gen ? " (" + item.gen + ")" : ""}`}
        onPress={() => {
          navigation.push("Detail", { entryID: item.e, senseID: item.s });
        }}
        titleStyle={{ fontSize: userPrefs.compressed ? 13 : undefined }}
        style={{
          marginVertical: userPrefs.compressed ? -3 : undefined,
          paddingVertical: userPrefs.compressed ? 2 : undefined,
          backgroundColor: backgroundColor,
        }}
      />
    </View>
  );
};
