import { useEffect, useState } from "react";
import { makeAPIRequest } from "../services/APIService";

const useSearchResult = (
  langIn: string[],
  langOut: string[],
  term: string
): { isLoading: boolean; results: any; error: Error | null } => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);

  const requestString = `search?term=${term}&langs_in=${langIn}&langs_out=${langOut}`;

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const request = await makeAPIRequest(requestString);
      if (request.ok) {
        setIsLoading(false);
        setResults(request.value);
      } else {
        setIsLoading(false);
        setError(request.error);
      }
    };

    fetchData();
  }, [requestString]);

  return { isLoading, results, error };
};

export default useSearchResult;
