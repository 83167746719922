import * as React from "react";
import { Appbar } from "react-native-paper";
import { useRoute } from "@react-navigation/native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";

const TopBar = ({ navigation, back }: NativeStackHeaderProps) => {
  const route = useRoute();

  return (
    <Appbar.Header>
      {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      <Appbar.Content title={route.name} />
      {route.name !== "Home" && route.name !== "Search" && (
        <Appbar.Action
          icon="magnify"
          onPress={() => {
            navigation.navigate("Home");
          }}
        />
      )}
      <Appbar.Action
        icon="cog-outline"
        onPress={() => {
          navigation.navigate("Preferences");
        }}
      />
    </Appbar.Header>
  );
};

export default TopBar;
