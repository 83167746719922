import {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  Dispatch,
  useEffect,
  useState,
  useCallback,
} from "react";

const initialState = {
  errorMessage: "",
  errorVisible: false,
  addError: (errorMessage: string) => {},
  clearError: () => {},
};

type ErrorBoxStateType = typeof initialState;

const ErrorBoxContext = createContext<ErrorBoxStateType>(initialState);

export const useErrorBox = () => useContext(ErrorBoxContext);

export const ErrorBoxProvider = ({ children }: { children: ReactNode }) => {
  const [errorMessage, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const clearError = () => {
    setError("");
    setErrorVisible(false);
  };
  const addError = (errorMessage: string) => {
    setError(errorMessage);
    setErrorVisible(true);
  };

  const contextValue = {
    errorMessage: errorMessage,
    errorVisible: errorVisible,
    addError: useCallback((errorMessage: string) => addError(errorMessage), []),
    clearError: useCallback(clearError, []),
  };

  return (
    <ErrorBoxContext.Provider value={contextValue}>
      {children}
    </ErrorBoxContext.Provider>
  );
};
