import { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import {
  IconButton,
  List,
  Modal,
  Portal,
  Surface,
  Text,
  useTheme,
} from "react-native-paper";
import { ThemeOverride } from "../../../App";
import { useLanguages } from "../../context/languages";
import { ContentSurface } from "../ContentSurface";
import { LanguageItem } from "./LanguageItem";

export const LanguageList = ({
  title,
  subtitle,
  langList,
  modifyMode,
  addLanguage,
  removeLanguage,
  onMoveUp,
  onMoveDown,
}: any) => {
  const theme: ThemeOverride = useTheme();
  const { availableLanguages: availableLangs } = useLanguages();
  const [isModifying, setIsModifying] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const filteredAvailableLangs = () => {
    return availableLangs.filter((lang: string) => {
      return !langList.includes(lang);
    });
  };

  const modalStyle = {
    backgroundColor: theme.colors.background,
    padding: theme.padding + 12,
    paddingHorizontal: 30,
    margin: theme.padding,
  };

  const handlePress = isModifying
    ? (lang: string) => {
        removeLanguage(lang);
      }
    : () => {};
  const mode = isModifying ? "modify" : undefined;

  return (
    <ContentSurface>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ flex: 5 }}>
          <Text variant="headlineMedium">{title}</Text>
          <Text variant="bodySmall">{subtitle}</Text>
        </View>
        <View
          style={{
            flex: 2,
            alignItems: "center",
          }}
        >
          <IconButton
            icon={isModifying ? "check" : "pencil"}
            mode="contained"
            onPress={() => {
              setIsModifying(!isModifying);
            }}
          />
        </View>
      </View>
      <View style={{ marginHorizontal: 30, marginVertical: theme.padding }}>
        <List.Section>
          {langList.map((lang: string, index: number) => {
            // give style to only last element
            const lastStyle =
              index === langList.length - 1
                ? { borderBottomWidth: 1 }
                : undefined;
            return (
              <LanguageItem
                key={lang}
                lang={lang}
                handlePress={handlePress}
                mode={mode}
                style={lastStyle}
                onMoveUp={() => {
                  onMoveUp(index);
                }}
                onMoveDown={() => {
                  onMoveDown(index);
                }}
              />
            );
          })}
          {isModifying && (
            <LanguageItem
              text="Add another language"
              mode="add"
              handlePress={() => {
                setIsAdding(true);
              }}
              style={{ borderTopWidth: 0, borderBottomWidth: 1 }}
            />
          )}
          {isAdding && (
            <Portal>
              <Modal
                visible={true}
                onDismiss={() => {
                  setIsAdding(false);
                }}
                contentContainerStyle={modalStyle}
              >
                <ScrollView>
                  {filteredAvailableLangs().map(
                    (lang: string, index: number) => {
                      // give style to only last element
                      const lastStyle =
                        index === filteredAvailableLangs().length - 1
                          ? { borderBottomWidth: 1 }
                          : undefined;
                      return (
                        <LanguageItem
                          key={lang}
                          lang={lang}
                          mode="add"
                          handlePress={() => {
                            addLanguage(lang);
                            setIsAdding(false);
                          }}
                          style={lastStyle}
                        />
                      );
                    }
                  )}
                </ScrollView>
              </Modal>
            </Portal>
          )}
        </List.Section>
      </View>
    </ContentSurface>
  );
};
