import * as React from "react";
import { ScrollView, View, Linking } from "react-native";
import { Button, Text, useTheme } from "react-native-paper";
import { ThemeOverride } from "../../App";
import { ContentSurface } from "../components/ContentSurface";

export const HelpScreen = ({ navigation }: any) => {
  const theme: ThemeOverride = useTheme();
  return (
    <View style={{ flex: 1 }}>
      <ScrollView>
        <ContentSurface>
          <Text variant="headlineSmall">
            Polydict: A multilingual reference dictionary for language learners
            and enthusiasts!
          </Text>
        </ContentSurface>
        <ContentSurface>
          <Text variant="bodyLarge">
            Polydict is a translation and reference dictionary for those who
            speak or are learning multiple languages. Search for a word in any
            of the languages you speak, and immediately see translations, hear
            pronunciations, read definitions, and explore usage-data in other
            languages.
          </Text>
          <Text variant="bodyLarge" style={{ marginTop: 8 }}>
            Not sure where to start? Configure the languages that you are
            interested in on the Preferences Screen. Then, search for a word!
          </Text>
          <Button
            style={{ marginTop: 8 }}
            onPress={() => {
              navigation.navigate("Preferences");
            }}
            icon="cog-outline"
            mode="contained-tonal"
          >
            <Text>Configure Languages</Text>
          </Button>
          <Button
            style={{ marginTop: 8 }}
            onPress={() => {
              navigation.navigate("Search", { query: "test" });
            }}
            icon="magnify"
            mode="contained-tonal"
          >
            <Text>Search for a word</Text>
          </Button>
        </ContentSurface>
        <ContentSurface>
          <Text variant="bodyLarge">
            Polydict currently contains over 1,700,000 words across 7 different
            languages: English, Spanish, German, Italian, Russian, Portuguese,
            and French. We are working hard to add more words and languages all
            of the time. Have suggestions? We’d love to hear from you.
          </Text>
          <Button
            style={{ marginTop: 8 }}
            onPress={() => {
              Linking.openURL(
                `mailto:info@polydict.xyz?subject=Polydict+Feedback`
              );
            }}
            icon="email"
            mode="contained-tonal"
          >
            <Text>Submit feedback via email</Text>
          </Button>
        </ContentSurface>
      </ScrollView>
    </View>
  );
};
