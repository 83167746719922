import {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  Dispatch,
  useEffect,
  useState,
} from "react";
import { preferencesReducer } from "./reducer";
import AsyncStorage from "@react-native-async-storage/async-storage";

export type preferencesStateType = {
  searchLangs: string[];
  glossLangs: string[];
  expanded: {
    [key: string]: boolean;
  };
  compressed: boolean;
  colorsMode: boolean;
  searchHistory: string[];
};

const initialState = {
  searchLangs: ["es", "de", "it", "en"],
  glossLangs: ["en", "it"],
  expanded: { es: true, de: true, it: true, en: false },
  compressed: true,
  colorsMode: true,
  searchHistory: [],
} as preferencesStateType;

export const storeData = async (state: preferencesStateType) => {
  try {
    await AsyncStorage.setItem(
      "@polydictPreferencesState",
      JSON.stringify(state)
    );
  } catch (e) {
    console.error("Error saving storage");
  }
};

export const getData = async (): Promise<preferencesStateType | undefined> => {
  try {
    const jsonValue = await AsyncStorage.getItem("@polydictPreferencesState");
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    console.error("Error reading storage");
  }
};

const PreferencesContext = createContext<{
  state: preferencesStateType;
  dispatch: Dispatch<any>;
}>({ state: initialState, dispatch: () => null });

export const usePreferences = () => useContext(PreferencesContext);

export const PreferencesProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(preferencesReducer, initialState);

  // Load initial state
  useEffect(() => {
    async function fetchData() {
      const loadedState = await getData();
      if (loadedState) {
        dispatch({
          type: "LOAD_INITIAL_STATE",
          payload: { initialState: { ...initialState, ...loadedState } },
        });
      }
    }
    fetchData();
  }, []);

  // Persist state on each update and batch updates for server save
  useEffect(() => {
    // This check is required to avoid initial writing to asyncStorage
    if (state) {
      storeData(state);
    }
  }, [state]);

  return (
    <PreferencesContext.Provider value={{ state, dispatch }}>
      {children}
    </PreferencesContext.Provider>
  );
};
