import { View, Platform } from "react-native";
import { IconButton, List, Text, useTheme } from "react-native-paper";
import { ThemeOverride } from "../../../App";
import { useLanguages } from "../../context/languages";

export const LanguageItem = ({
  lang,
  text,
  handlePress,
  style,
  mode,
  onMoveUp,
  onMoveDown,
}: any) => {
  const theme: ThemeOverride = useTheme();
  const { languageNames } = useLanguages();
  const right = () => {
    if (mode === "modify") {
      return (
        <View style={{ flexDirection: "row" }}>
          <IconButton
            icon="arrow-down-bold"
            mode="contained-tonal"
            onPress={() => {
              onMoveDown();
            }}
          />
          <IconButton
            icon="minus"
            style={{ backgroundColor: theme.colors.removing }}
            mode="contained-tonal"
            onPress={() => {
              handlePress(lang);
            }}
          />
          <IconButton
            icon="arrow-up-bold"
            mode="contained-tonal"
            onPress={() => {
              onMoveUp();
            }}
          />
        </View>
      );
    } else if (mode === "add") {
      return (
        <View style={{ flexDirection: "row" }}>
          <IconButton
            icon="plus"
            style={{ backgroundColor: theme.colors.adding }}
            mode="contained-tonal"
            onPress={() => {
              handlePress(lang);
            }}
          />
        </View>
      );
    } else {
      return undefined;
    }
  };

  // Bug with React-Native-Paper currently has List.Item with aria-disabled=true in React Web
  const NativeOrWeb = Platform.select({
    native: () => (
      <List.Item
        title={text ? text : languageNames[lang].native}
        right={right}
        style={[
          {
            borderTopWidth: 1,
            borderColor: theme.colors.surfaceVariant,
          },
          style,
        ]}
      />
    ),
    default: () => (
      <View>
        <Text variant="bodyLarge">
          {text ? text : languageNames[lang].native}
        </Text>
        {right()}
      </View>
    ),
  });

  return <NativeOrWeb />;
};
