import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useEffect, useRef } from "react";
import { FlatList, Modal, ScrollView, StyleSheet, View } from "react-native";
import {
  ActivityIndicator,
  Divider,
  List,
  Snackbar,
  Surface,
  Text,
  useTheme,
} from "react-native-paper";
import { Item } from "react-native-paper/lib/typescript/components/List/List";
import { ThemeOverride } from "../../App";
import { SenseItem } from "../components/Search/SenseItem";
import { SearchBar } from "../components/SearchBar";
import { useErrorBox } from "../context/errorBox";
import { usePreferences } from "../context/preferences";
import useSearchResult from "../hooks/useSearchResult";
import { RootStackParamList } from "../types/Types";

type SearchScreenProps = NativeStackScreenProps<RootStackParamList, "Search">;

export const SearchScreen = ({ navigation, route }: SearchScreenProps) => {
  if (!route.params.query) {
    navigation.navigate("Home");
  }

  const { addError } = useErrorBox();
  const { state: userPrefs } = usePreferences();
  const scrollViewRef = useRef<FlatList | null>(null);

  // sort results so that words with higher numbers of translations are favored, but words which are in the first few results are weighted
  // even more highly
  const sortResults = (results: any) => {
    results.sort((a: any, b: any) => {
      // Calculate the total number of translations for each object
      const aTranslations = Object.values(a.translations).reduce(
        (acc: number, cur: any) => acc + cur.length,
        0
      );
      const bTranslations = Object.values(b.translations).reduce(
        (acc: number, cur: any) => acc + cur.length,
        0
      );

      // Get the index of each object in the list
      const aIndex = results.indexOf(a);
      const bIndex = results.indexOf(b);

      // Calculate the weight for the index of each object
      const aWeight = aIndex < 2 ? 5 + (2 - aIndex) : 1;
      const bWeight = bIndex < 2 ? 5 + (2 - bIndex) : 1;

      // Sort based on the total number of translations, weighted by the index
      return bTranslations * bWeight - aTranslations * aWeight;
    });
  };

  const scrollToPos = (index: number) => {
    setTimeout(() => {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToIndex({
          index: index,
          viewPosition: 0,
        });
      }
      // enough time for render to complete and new index location to be registered
    }, 250);
  };

  const { isLoading, error, results } = useSearchResult(
    userPrefs.searchLangs,
    userPrefs.searchLangs,
    route.params.query
  );

  if (results) {
    sortResults(results);
  }

  // scroll back to top when results change
  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToOffset({ animated: true, offset: 0 });
    }
  }, [results]);

  // add errors to snackbox
  useEffect(() => {
    if (error) {
      addError(error.message);
    }
  }, [error]);

  const renderItem = ({ item, index }: any) => (
    <SenseItem
      key={item.sense._id}
      word={item}
      index={index}
      scrollToPos={scrollToPos}
    />
  );

  return (
    <View style={{ flex: 1 }}>
      <SearchBar searchQuery={route.params.query} isLoading={isLoading} />
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          {results && (
            <FlatList
              data={results}
              renderItem={renderItem}
              ref={scrollViewRef}
            />
          )}
        </View>
      </View>
    </View>
  );
};
