import {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  Dispatch,
  useEffect,
  useState,
} from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import Constants from "expo-constants";
import * as SecureStore from "expo-secure-store";
import jwtDecode from "jwt-decode";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { API_URL } from "../../services/APIService";
import {
  getData,
  preferencesStateType,
  storeData,
} from "../preferences/context";
import { usePreferences } from "../preferences";

export type userStateType = {
  loggedIn: boolean;
  userInfo?: UserInfo;
  userStats?: any;
};

export type UserInfo = {
  name: string;
  email: string;
  picture: string;
  sub: string;
  roles: string[];
};

const initialState = {
  loggedIn: false,
  userInfo: undefined,
  userStats: undefined,
} as userStateType;

const auth0 = new Auth0Client({
  domain: Constants.expoConfig?.extra?.AUTH0_DOMAIN,
  clientId: Constants.expoConfig?.extra?.AUTH0_CLIENT,
  cacheLocation: "localstorage",
});

const secureSave = async (key: string, value: string) => {
  await AsyncStorage.setItem(key, value);
};

const secureLoad = async (key: string) => {
  return await AsyncStorage.getItem(key);
};

const secureRemove = async (key: string) => {
  await AsyncStorage.removeItem(key);
};

const getUserInfo = async (claims: any): Promise<UserInfo | null> => {
  if (!claims) {
    return null;
  }
  const userInfo = claims;

  if (userInfo.exp < Date.now() / 1000) {
    // console.log("User ID Token Expired");
    return null;
  }

  return {
    name: userInfo.name,
    email: userInfo.email,
    picture: userInfo.picture,
    sub: userInfo.sub,
    roles: userInfo["https://polydict.xyz/roles"],
  };
};

const UserContext = createContext<{
  state: userStateType;
  onLogin: () => void;
  onLogout: () => void;
  onSavePreferences: () => void;
}>({
  state: initialState,
  onLogin: () => {},
  onLogout: () => {},
  onSavePreferences: () => {},
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<userStateType>(initialState);
  const [lastServerPrefs, setLastServerPrefs] = useState<string>("");

  const { dispatch } = usePreferences();

  const onSuccessfulAuthentication = async () => {
    const claims = await auth0.getIdTokenClaims();
    const userInfo = await getUserInfo(claims);
    if (userInfo) {
      onSuccessfulLogin(userInfo);
    }
  };

  const onSuccessfulLogin = async (userInfo: UserInfo) => {
    setState((prevState) => {
      return { ...prevState, loggedIn: true, userInfo };
    });
    const ACCESS_TOKEN = await secureLoad("accessToken");
    const response = await fetch(`${API_URL}userUpdate`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
      body: JSON.stringify({ userID: userInfo.sub, action: "login" }),
    });
    response.json().then((userData: any) => {
      setState((prevState) => {
        return { ...prevState, userStats: userData.userStats };
      });
      if (userData.userPreferences) {
        setLastServerPrefs(JSON.stringify(userData.userPreferences));
        // load server preferences into state
        dispatch({
          type: "LOAD_INITIAL_STATE",
          payload: { initialState: userData.userPreferences },
        });
      }
    });
  };

  const onSuccessfulLogout = async () => {
    setState({
      ...state,
      loggedIn: false,
      userInfo: undefined,
      userStats: undefined,
    });
  };

  const updateUserPreferencesOnServer = async (
    userID: string,
    payload: any
  ) => {
    // console.log("saving preferences to server");
    const ACCESS_TOKEN = await secureLoad("accessToken");
    const response = await fetch(`${API_URL}userUpdate`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
      body: JSON.stringify({ userID, action: "updatePreferences", payload }),
    });
  };

  const onSavePreferences = async () => {
    if (state.loggedIn && state.userInfo?.sub) {
      const preferences = await getData();
      // prevent unnecessary saves to server
      if (lastServerPrefs !== JSON.stringify(preferences)) {
        updateUserPreferencesOnServer(state.userInfo.sub, preferences);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isAuthenticated = await auth0.isAuthenticated();
        if (isAuthenticated) onSuccessfulAuthentication();
        // check for the code and state parameters which are set during the callback from auth0
        const query = window.location.search;
        if (query.includes("code=") && query.includes("state=")) {
          // Process the login state
          // console.log("processing callback");
          await auth0.handleRedirectCallback();
          const isAuthenticated = await auth0.isAuthenticated();

          if (isAuthenticated) onSuccessfulAuthentication();

          // Use replaceState to redirect the user away and remove the querystring parameters
          window.history.replaceState({}, document.title, "/");
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const onLogin = async () => {
    try {
      await auth0.loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.href,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onLogout = async () => {
    try {
      await auth0.logout({
        logoutParams: {
          returnTo: window.location.href,
        },
      });
      await secureRemove("idToken");
      onSuccessfulLogout();
    } catch (e) {
      // console.log("Log out cancelled");
    }
  };

  return (
    <UserContext.Provider
      value={{
        state,
        onLogin,
        onLogout,
        onSavePreferences,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
