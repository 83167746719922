import { useEffect, useState } from "react";
import { makeAPIRequest } from "../services/APIService";
import { Types } from "mongoose";

const useEntryTranslations = (
  entryID: Types.ObjectId,
  langOut: string[]
): { isLoading: boolean; results: any; error: Error | null } => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);

  const requestString = `entryTranslations?entryID=${entryID}&langs_out=${langOut}`;

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const request = await makeAPIRequest(requestString);
      if (request.ok) {
        setIsLoading(false);
        setResults(request.value);
      } else {
        setIsLoading(false);
        setError(request.error);
      }
    };

    fetchData();
  }, [requestString]);

  return { isLoading, results, error };
};

export default useEntryTranslations;
